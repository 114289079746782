import jost500 from './assets/jost-v6-latin-500.woff2';
import jost600 from './assets/jost-v6-latin-600.woff2';
import jostRegular from './assets/jost-v6-latin-regular.woff2';

const fonts = `
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  src: url('${jostRegular}') format('woff2');
}
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  src: url('${jost500}') format('woff2');
}
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  src: url('${jost600}') format('woff2');
}
`;

export default fonts;
