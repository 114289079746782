import {QrReader} from "react-qr-reader";
import queryString from "query-string";
import styled from "styled-components";

const ScannerStyled = styled.div`
    //border: 1px solid #666;
    background-color: #ddd;
    margin-bottom: 20px;
`;

const Scanner = ({ onResult, currentResult }) => {
    return (
      <ScannerStyled>
        <QrReader
          constraints={{facingMode: 'environment'}}
          onResult={(result, error) => {
            if (!!result) {
              const paramString = result.text;
              const params = queryString.parse(paramString);
              if (onResult && !currentResult) {
                onResult(params);
              }
            }
          }}
        >
        </QrReader>
      </ScannerStyled>
    );
  }
;

export default Scanner;