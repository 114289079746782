import styled from 'styled-components';
import {Link, useLocation} from "react-router-dom";

const MainNavStyled = styled.nav`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
`;

const MainButton = styled(Link)`
    display: flex;
    padding: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 50%;
    text-decoration: none;
    font-weight: 500;
    
    background-color: ${({$active}) => $active ? '#177C9A' : '#E4D2AB'};
    color: ${({$active}) => $active ? '#fff' : '#000'};
`;

const MainNav = () => {
  const location = useLocation();

  return (
    <MainNavStyled>
      <MainButton to="/" $active={location.pathname === '/'}>Home</MainButton>
      <MainButton to="/workshops" $active={location.pathname === '/workshops'}>Workshops</MainButton>
      <MainButton to="/scanner" $active={location.pathname === '/scanner'}>Scannen</MainButton>
    </MainNavStyled>
  );
};

export default MainNav;