import styled from "styled-components";

const ListStyled = styled.div`
    margin-bottom: 40px;
`;

const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E4D2AB;

    p {
        margin: 10px 0;
    }
`;

const ListButton = styled.button`
    background: none;
    border: none;
    width: 40px;
    height: 40px;
`;

const ListCheckbox = styled.input.attrs({type: 'checkbox'})`
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    flex: 0;
    margin-right: 10px;
    background-color: #E4D2AB;
`;

const ListContent = styled.p`
    flex: 1;
`;
const WorkshopList = ({participants, removeParticipant, setApproved, started}) => {
  return (
    <ListStyled>
      {participants.length === 0 && (<ListItem><p>Keine Teilnehmer</p></ListItem>)}
      {participants.map((participant, index) => (
        <ListItem key={index}>
          { started ? <p>
            <ListCheckbox
              checked={participant.approved === true}
              onChange={() => setApproved(participant.uID, !(participant.approved === true))}
            />
          </p> : null }
          <ListContent
            onClick={() => setApproved(participant.uID, !(participant.approved === true))}
          >{participant.firstName} {participant.lastName}</ListContent>
          { !started ? (
            <ListButton onClick={() => {
              removeParticipant(participant.uID);
            }}>
              <svg width="20px" height="20px" version="1.1" viewBox="0 0 1200 1200"
                   xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path
                    d="m978 295.2h-225.6v-45.598c0-22.281-8.8477-43.645-24.602-59.398s-37.117-24.602-59.398-24.602h-136.8c-22.281 0-43.645 8.8477-59.398 24.602s-24.602 37.117-24.602 59.398v45.602l-225.6-0.003906c-12.863 0-24.746 6.8633-31.176 18-6.4336 11.141-6.4336 24.863 0 36 6.4297 11.141 18.312 18 31.176 18h73.199v535.2c0 35.012 13.906 68.586 38.664 93.34 24.754 24.754 58.328 38.66 93.336 38.66h345.6c35.008 0 68.582-13.906 93.336-38.66 24.758-24.754 38.664-58.328 38.664-93.34v-535.2h73.199c12.863 0 24.746-6.8594 31.176-18 6.4336-11.137 6.4336-24.859 0-36-6.4297-11.137-18.312-18-31.176-18zm-458.4-45.598c0-6.6289 5.3711-12 12-12h136.8c3.1836 0 6.2383 1.2617 8.4883 3.5117s3.5117 5.3047 3.5117 8.4883v45.602l-160.8-0.003906zm312 654c0 15.91-6.3242 31.172-17.574 42.426-11.254 11.25-26.516 17.574-42.426 17.574h-344.4c-15.914 0-31.172-6.3242-42.426-17.574-11.254-11.254-17.574-26.516-17.574-42.426v-536.4h465.6z"/>
                  <path
                    d="m690 876c9.5469 0 18.703-3.793 25.457-10.543 6.75-6.7539 10.543-15.91 10.543-25.457v-358.8c0-12.859-6.8633-24.746-18-31.176-11.137-6.4297-24.863-6.4297-36 0-11.137 6.4297-18 18.316-18 31.176v358.8c0 9.5469 3.793 18.703 10.543 25.457 6.7539 6.75 15.91 10.543 25.457 10.543z"/>
                  <path
                    d="m510 876c9.5469 0 18.703-3.793 25.457-10.543 6.75-6.7539 10.543-15.91 10.543-25.457v-358.8c0-12.859-6.8633-24.746-18-31.176-11.137-6.4297-24.863-6.4297-36 0-11.137 6.4297-18 18.316-18 31.176v358.8c0 9.5469 3.793 18.703 10.543 25.457 6.7539 6.75 15.91 10.543 25.457 10.543z"/>
                </g>
              </svg>

            </ListButton>
          ) : null}
        </ListItem>
      ))}
    </ListStyled>
  );
}

export default WorkshopList;