import styled from "styled-components";

import Scanner from "../Scanner/Scanner";
import Button from "../Button/Button";
import {useState} from "react";
import {useLocalStorage} from "react-use";
import {useNavigate} from "react-router-dom";

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    //background-color: white;
`;

const WindowStyled = styled.div`
    position: relative;
    padding: 20px 20px 100px;
`;

const ScanResult = styled.div`
    position: fixed;
    left: 40px;
    bottom: 40px;
    z-index: 100;
    background-color: #E4D2AB;
    width: calc(100% - 80px);
    padding: 20px;
    border-radius: 14px;
`;

const ScannerWin = ({onClose}) => {
  const [data, setData] = useState(null);
  const [participants, setParticipants] = useLocalStorage('participants', []);

  const navigate = useNavigate();

  const existingParticipant = participants.find(p => p?.uID === data?.uID);

  const addToWorkshop = (data, workshop) => {
    const participantData = {
      ...data,
      workshop
    };
    // If already in list, only update workshop
    if (existingParticipant) {
      existingParticipant.workshop = workshop;
      setParticipants([...participants]);
      setData(null);
      return;
    }
    setParticipants([...participants, participantData]);
    setData(null);
  }

  return (
    <Overlay>
      <WindowStyled>
        <h1>QR Code scannen</h1>
        <Scanner currentResult={data} onResult={
          (result) => {
            setData(result);
          }
        }/>
        {data ? (
          <ScanResult success>
            <h2>QR Code erkannt:</h2>
            <h1>{data.firstName} {data.lastName}</h1>
            { existingParticipant ? (<p>Bereits gescannt (Workshop {existingParticipant.workshop})</p>) : null }
            <h3>Workshop 1: {participants.filter(p => p.workshop === 1).length} Teilnehmer</h3>
            <p>
              <Button onClick={() => {
                addToWorkshop(data, 1);
              }}>Zu Workshop 1 hinzufügen</Button>
            </p>
            <h3>Workshop 2: {participants.filter(p => p.workshop === 2).length} Teilnehmer</h3>
            <p>
              <Button onClick={() => {
                addToWorkshop(data, 2);
              }}>Zu Workshop 2 hinzufügen</Button>
            </p>
            <p>
              <Button onClick={() => {
                setData(null);
              }}>Abbrechen</Button>
            </p>
          </ScanResult>
        ) : (
          <ScanResult>
            <h3>Workshop 1: {participants.filter(p => p.workshop === 1).length} Teilnehmer</h3>
            <h3>Workshop 2: {participants.filter(p => p.workshop === 2).length} Teilnehmer</h3>
            <Button onClick={() => navigate('/workshops')}>Zurück</Button>
          </ScanResult>
        )}

      </WindowStyled>
    </Overlay>
  );
}

export default ScannerWin;