import {ThemeProvider} from "styled-components";
import GlobalStyle from "./styles/globals.js";
import {BrowserRouter} from "react-router-dom";
import Main from "./components/Main/Main.jsx";

export const base_url = import.meta.env.DEV ? "http://stripeconapp.test" : "";

const App = () => {
  return (
    <>
      <GlobalStyle/>
      <BrowserRouter>
        <Main/>
      </BrowserRouter>
    </>
  )
}

export default App;
