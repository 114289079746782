import styled from "styled-components";

import {useLocalStorage} from "react-use";
import Button from "../Button/Button";
import {useNavigate} from "react-router-dom";
import WorkshopList from "./WorkshopList";
import {base_url} from "../Main/Main";
import useCurrentTime from "../../utilities/useCurrentTime";
import {useEffect, useState} from "react";

const WorkshopListStyled = styled.div`
    padding-top: 80px;
`;

const ButtonRow = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
`;

const WorkshopPage = () => {
  const [participants, setParticipants] = useLocalStorage('participants', []);
  const [participantsChanged, setParticipantsChanged] = useState(false)
  const navigate = useNavigate();
  const [userId, setUserId] = useLocalStorage('userId', 0);
  const [hash, setHash] = useLocalStorage('hash', '');
  const [eventDate, setEventDate] = useLocalStorage('eventDate', '');

  const currentDate = useCurrentTime(1000 * 10);
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();
  const eventDateObj = new Date(eventDate);

  const eventStarted = (currentDate >= eventDateObj && currentHour >= 10)
  const registrationStarted = (currentDate >= eventDateObj && (currentHour >= 12 || (currentHour >= 11 && currentMinute >= 30)))
  const workshopsStarted = (currentDate >= eventDateObj && currentHour >= 12)
  const eventEnded = (currentDate >= eventDateObj && currentHour >= 13)

  const syncParticipants = () => {
    // Sync with server, send only uIDs
    fetch(`${base_url}/participate/sync/${userId}/${hash}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(participants.filter(p => p.approved).map(p => p.uID))
    }).then((res) => {
      console.log('Ok');
    });
  }

  useEffect(() => {
    if (participantsChanged) {
      syncParticipants();
      setParticipantsChanged(false);
    }
  }, [currentDate])

  return (
    <WorkshopListStyled>
      <ButtonRow>
        {eventEnded ? (
          <Button onClick={() => {
            // Sync with server, send only uIDs
            fetch(`${base_url}/participate/sync/${userId}/${hash}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(participants.filter(p => p.approved).map(p => p.uID))
            })
              .then((res) => {
                alert('Ok');
              })
          }}>Sync</Button>
        ) : null}
        {!eventStarted || (eventStarted && registrationStarted) ? (<Button onClick={() => {
          navigate('/scanner');
        }}>QR-Code scannen</Button>) : null}
      </ButtonRow>
      <h2>Workshop 1 / 12:00-12:30</h2>
      <WorkshopList
        participants={participants.filter(p => p.workshop === 1)}
        removeParticipant={(uID) => {
          setParticipants(participants.filter(p => p.uID !== uID));
        }}
        started={workshopsStarted}
        setApproved={(uID, approved) => {
          setParticipants(participants.map(p => {
            if (p.uID === uID) {
              p.approved = approved;
            }
            return p;
          }));
          setParticipantsChanged(true);
        }}
      />
      <h2>Workshop 2 / 12:30-13:00</h2>
      <WorkshopList
        participants={participants.filter(p => p.workshop === 2)}
        removeParticipant={(uID) => {
          setParticipants(participants.filter(p => p.uID !== uID));
        }}
        started={workshopsStarted}
        setApproved={(uID, approved) => {
          setParticipants(participants.map(p => {
            if (p.uID === uID) {
              p.approved = approved;
            }
            return p;
          }));
          setParticipantsChanged(true);
        }}
      />
    </WorkshopListStyled>
  )
}

export default WorkshopPage;