import styled from "styled-components";

const ButtonStyled = styled.a`
    display: inline-block;
    padding: 14px 30px;
    background-color: #177C9A;
    color: white;
    border-radius: 14px;
    cursor: pointer;
    font-weight: 500;
`;

const Button = ({ onClick, children }) => (
  <ButtonStyled onClick={onClick}>
    {children}
  </ButtonStyled>
)

export default Button;