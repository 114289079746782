import {useEffect, useState} from "react";

const useCurrentTime = (msBetweenUpdates = 1000) => {
    const [curTime, setCurTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurTime(new Date());
        }, msBetweenUpdates);
        return () => clearInterval(intervalId);
    }, [msBetweenUpdates]);

    return curTime;
}

export default useCurrentTime;