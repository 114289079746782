import styled from 'styled-components';
import MainNav from "../MainNav/MainNav";
import SlideRoutes from "react-slide-routes";
import {Route, Routes, useSearchParams} from "react-router-dom";
import WorkshopPage from "../WorkshopPage/WorkshopPage";
import ScannerWin from "../ScannerWin/ScannerWin";
import InfoPage from "../InfoPage/InfoPage";
import {useEffect} from "react";
import {useLocalStorage} from "react-use";
import useCurrentTime from "../../utilities/useCurrentTime";

const MainStyled = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    max-width: 600px;
    height: 100%;
    margin: 0 auto;
`;

const MainContent = styled.div`
    flex: 1;
    padding: 20px 20px 100px;
`;

export const base_url = import.meta.env.DEV ? "http://localhost/itaw-db/public" : "https://db.infotag-arbeitswelt.de";

const Main = ({children}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const [userName, setUserName] = useLocalStorage('username', '');
  const [userId, setUserId] = useLocalStorage('userId', 0);
  const [hash, setHash] = useLocalStorage('hash', '');
  const [eventDate, setEventDate] = useLocalStorage('eventDate', '');

  useEffect(() => {
    const id = searchParams.get('id');
    const hash = searchParams.get('hash');
    if (!id || !hash) return;
    fetch(`${base_url}/participate/loginapp/${id}/${hash}`)
      .then((res) => res.json())
      .then((data) => {
        setUserName(data.name);
        setUserId(data.id);
        setHash(hash);
        setEventDate(data.date);
      })
  }, [])

  const currentDate = useCurrentTime(1000 * 30);
  const currentHour = currentDate.getHours();
  const eventDateObj = new Date(eventDate);
  if(currentDate >= eventDateObj && currentHour > 15) {
    return (<MainStyled>
      <MainContent>
        <h1>Der Infotag ist vorbei</h1>
        <p>Vielen Dank für Ihre Teilnahme.</p>
      </MainContent>
    </MainStyled>);
  }

  return (<MainStyled>
      {userId ? (<>
          <MainContent>
            <SlideRoutes>
              <Route path="/" element={<InfoPage/>}/>
              <Route path="/workshops" element={<WorkshopPage/>}/>
              <Route path="/scanner" element={<ScannerWin/>}/>
            </SlideRoutes>
          </MainContent>
          <MainNav/>
        </>) : (<MainContent>
          <h1>Nicht eingeloggt</h1>
        <p>Bitte verwenden Sie den Link in der Mail.</p>
        </MainContent>)}
    </MainStyled>);
}

export default Main;